import { Box, Flex, HStack, IconButton } from '@chakra-ui/react';

import { H2 } from './Blocks/H2';
import Image from 'next/image';
import { Title } from './Blocks/Title';
import { AnimatePresence, motion } from 'framer-motion';
import { IconChevron } from 'icons';
import { useState } from 'react';
import { useGetGarage } from 'hooks';
import { CarTier, RarityTier } from 'types';
import RTiers from 'components/Tiers/Tiers';
import { RPositionAndPoints } from 'components';

import EmptyGarage from '../../public/images/home/empty_garage.png';
import { useRouter } from 'next/navigation';

export const ViewCarsPanel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0); // 1 for next, -1 for prev
  const [isAnimating, setIsAnimating] = useState(false);

  const { data: garageData } = useGetGarage(false);
  const cars = garageData?.getGarage;

  const nextItem = () => {
    if (isAnimating) return;

    if (cars && cars.length > 0) {
      setDirection(1);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cars.length);
      setIsAnimating(true);
    }
  };

  const prevItem = () => {
    if (isAnimating) return;

    if (cars && cars.length > 0) {
      setDirection(-1);
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + cars.length) % cars.length
      );
      setIsAnimating(true);
    }
  };

  const variants = {
    enter: (direction: number) => ({
      x: direction === 1 ? 200 : -200,
      scale: 0.5,
      opacity: 0,
    }),
    center: {
      x: 0,
      scale: 1,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction === 1 ? -200 : 200,
      scale: 0.5,
      opacity: 0,
    }),
  };

  const router = useRouter();

  return (
    <Flex w="100%" h="100%" bg="rgba(0, 0, 0, 0.4)" role="group">
      <Flex
        w="100%"
        h="100%"
        position="relative"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.4) 50%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.4) 50%)',
        }}
      >
        {/* HEADER */}
        <Flex
          position="absolute"
          top={{ base: 4, md: 4 }}
          left={{ base: 4, md: 4 }}
          gap={3}
        >
          <Title maxW="340px">View Car</Title>
          {cars && cars.length > 1 && (
            <Title maxW="340px" color="white" textTransform="none">
              {currentIndex + 1} of {cars?.length}
            </Title>
          )}
        </Flex>

        {/* CONTENT */}
        <Box
          position="relative"
          width="full"
          height="full"
          overflow="hidden"
          p={cars && cars.length > 0 ? 10 : 0}
        >
          <AnimatePresence
            initial={false}
            custom={direction}
            mode="sync"
            onExitComplete={() => setIsAnimating(false)}
          >
            {/* CAR IMAGE */}
            {cars && cars.length > 0 && (
              <motion.div
                onClick={() =>
                  router.push(`/garage/${cars?.[currentIndex]?.id || ''}`)
                }
                key={currentIndex}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 0.3, ease: 'backOut' }}
                style={{
                  position: 'absolute',
                  width: 'calc(100% - 100px)',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto',
                  height: '100%',
                  marginTop: '20px',
                  cursor: 'pointer',
                }}
              >
                <Image
                  src={`/images/cars/livery-${
                    cars?.[currentIndex].appliedLivery?.imageUrl
                      ? cars?.[currentIndex].appliedLivery?.imageUrl
                      : cars?.[currentIndex].livery
                  }.png`}
                  alt={`Image ${currentIndex}`}
                  fill
                  style={{ objectFit: 'contain', zIndex: 6 }}
                  sizes="(max-width: 768px) 100vw, 50vw"
                />
                <Image
                  src={`/images/cars/livery-shadow.png`}
                  alt={`Image ${currentIndex}`}
                  fill
                  style={{
                    objectFit: 'contain',
                    zIndex: 5,
                    filter: 'blur(3px)',
                  }}
                  sizes="(max-width: 768px) 100vw, 50vw"
                />
              </motion.div>
            )}

            {!cars ||
              (cars.length < 0 && (
                <Flex w="100%" h="100%" position="relative">
                  <Image
                    src={EmptyGarage}
                    alt="Empty Garage"
                    style={{ objectFit: 'cover' }}
                    fill
                    sizes="(max-width: 768px) 100vw, 50vw"
                  />
                </Flex>
              ))}

            {/* FOOTER */}
            <Flex
              onClick={() =>
                router.push(`/garage/${cars?.[currentIndex]?.id || ''}`)
              }
              direction="column"
              as={motion.div}
              position="absolute"
              bottom={{ base: 4, md: 4 }}
              left={{ base: 4, md: 4 }}
              gap={3}
              cursor="pointer"
            >
              <H2 maxW="340px" textTransform="none">
                {cars?.[currentIndex]?.name ||
                  'NEED A NEW CAR? Head over to the market.'}
              </H2>
              {cars && cars.length > 0 && (
                <HStack gap={2}>
                  <RTiers
                    tier={
                      (cars?.[currentIndex].rarity as RarityTier) || 'common'
                    }
                    tierType="rarity"
                  />
                  <RTiers
                    tier={
                      (cars?.[currentIndex].tier?.name as CarTier) || 'bronze'
                    }
                    tierType="driver"
                  />
                  <RPositionAndPoints
                    carPosition={1}
                    points={cars?.[currentIndex].points}
                  />
                </HStack>
              )}
            </Flex>
          </AnimatePresence>
          {cars && cars.length > 1 && (
            <>
              <IconButton
                variant="secondary"
                aria-label="Previous"
                icon={<IconChevron transform={'rotate(180deg)'} />}
                position="absolute"
                left="10px"
                top="50%"
                px={6}
                transform="translateY(-50%)"
                onClick={prevItem}
                zIndex={10}
                disabled={cars?.length && cars.length < 2 ? true : false}
              />
              <IconButton
                variant="secondary"
                aria-label="Next"
                px={6}
                icon={<IconChevron />}
                position="absolute"
                right="10px"
                top="50%"
                transform="translateY(-50%)"
                onClick={nextItem}
                zIndex={10}
                disabled={cars?.length && cars.length < 2 ? true : false}
              />
            </>
          )}
        </Box>

        <BottomBarLine />
      </Flex>
    </Flex>
  );
};

export const BottomBarLine = () => {
  return (
    <Flex
      w="calc(100% - 8px)"
      right={0}
      left={0}
      m="auto"
      h="6px"
      borderBottom="2px solid"
      borderX="2px solid"
      borderColor="whiteAlpha.300"
      position="absolute"
      bottom={1}
      transition="all ease-in-out 0.3s"
      _groupHover={{
        borderColor: 'whiteAlpha.600',
      }}
    />
  );
};
